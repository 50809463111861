import { type Site } from "@/types/Sites";
import { type RequestContext } from "./types";

export const buildDefaultBucketName = ({
  lang,
  region,
  site,
}: {
  lang: string;
  region: string;
  site: Site;
}): string => `${site}-${region}-${lang}-def`;

const DEFAULT_LANG = "en-US";
const DEFAULT_REGION = "US";
const DEFAULT_SITE: Site = "shopping";
const NCID_REGEX = /^[\w]+$/;
const UUID_REGEX =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const DEFAULT_REQUEST_CONTEXT: RequestContext = {
  appVersion: "unknown",
  bot: false,
  bucket: [
    buildDefaultBucketName({
      lang: DEFAULT_LANG,
      region: DEFAULT_REGION,
      site: DEFAULT_SITE,
    }),
  ],
  cloudRegion: "none",
  features: {},
  host: "shopping.yahoo.com",
  intl: DEFAULT_REGION.toLowerCase(),
  lang: DEFAULT_LANG,
  partner: "none",
  region: DEFAULT_REGION,
  searchParams: {},
  site: DEFAULT_SITE,
  timestamp: 0,
  traceId: "",
  webview: false,
  ynet: false,
};

const isValidNcid = (value: string): boolean => NCID_REGEX.test(value);

// Check for a valid content uuid format.
export const isValidUuid = (value: string): boolean => UUID_REGEX.test(value);

const uuidTest = (uuid: string): boolean => UUID_REGEX.test(uuid);

export const isValidUuidList = (list: string) =>
  list.split(",").every(uuidTest);

const isNumber = (value: string): boolean => !Number.isNaN(value);

/**
 * Mapping of allowed parameters with their validation functions,
 * which will be set in the x-yahoo-searchparams header.
 *
 * pnr_id: (Featurebar UUID) used to force a featurebar to render for testing purposes.
 * time: (Timestamp) used to mock astronomer special events module start time
 * tsnla: (List ID) used to fetch subnavigation data from a specific PNR list.
 * uuid: (Stream UUID) used to force a specific content item into the stream.
 * woeId: used to mock weather location
 */
export const searchParamsAllowlist = {
  cache: isNumber,
  ncid: isValidNcid,
  ntk: isValidUuidList,
  pnr_id: isValidUuid,
  time: isNumber,
  tsnla: isValidUuid,
  uuid: isValidUuid,
  woeId: isNumber,
};
